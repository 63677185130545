<div *ngIf="isOpen" class="settings-container">
  <div class="titlebar">
    <h1>
      User Settings
      <action-button color="#F8F8F8" action="close" tooltipTitle="Close Sidebar" (onClick)="onCloseSidebar()"></action-button>
    </h1>
  </div>
  <div class="settings">
    <section class="user-section">
      <div class="user-info">
        <div class="user-icon">
          <user-icon></user-icon>
          <div class="edit-icon {{ !iconURL ? 'no-user' : '' }}">
            <action-button action="edit" tooltipTitle="Edit Icon" (onClick)="onEditIcon()"></action-button>
          </div>
        </div>
        <div class="user-fields">
          <div class="user-field">
            <input [(ngModel)]="userName" type="text" autocomplete="off" data-lpignore="true" placeholder="User Name" />
            <span class="buttons">
              <action-button action="check" [disabled]="!userNameChanged" tooltipTitle="Confirm" (onClick)="onConfirmUserName()"></action-button>
              <action-button action="close" [disabled]="!userNameChanged" tooltipTitle="Reset" (onClick)="onResetUserName()"></action-button>
            </span>
          </div>
          <div class="user-field">
            <input [(ngModel)]="userEmail" type="text" autocomplete="off" data-lpignore="true" placeholder="Email" />
            <span class="buttons">
              <action-button action="check" [disabled]="!userEmailChanged" tooltipTitle="Confirm" (onClick)="onConfirmUserEmail()"></action-button>
              <action-button action="close" [disabled]="!userEmailChanged" tooltipTitle="Reset" (onClick)="onResetUserEmail()"></action-button>
            </span>
          </div>
        </div>
      </div>
      <div class="data-collection-row" (click)="onToggleDataCollection()">
        <a (click)="openDataCollectionDialog($event)">User Data Collection:</a>
        <switch-button [active]="dataCollectionActive"></switch-button>
      </div>
    </section>
    <section class="group-membership">
      <h2>
        Group Membership
        <push-button class="manage-groups-btn" buttonStyle="yellow" (onClick)="onOpenRequestGroupDialog()">Manage Groups</push-button>
      </h2>
      <div *ngFor="let group of groupsWithAccess" class="notification-item group-item">
        <span class="title" #tooltip="matTooltip" matTooltip="Relationship: {{ getGroupRelationship(group) }}">{{ group.name }}</span>
        <img class="group-icon" src="{{ getGroupIcon(group) }}" />
      </div>
      <div *ngIf="groupsWithAccess.length <= 0" class="empty">
        <span class="title">Click Manage Groups to join a group!</span>
      </div>
    </section>
    <section class="notifications">
      <span class="notifications-header">
        <h2>Notifications</h2>
        <span class="col-labels">
          <span class="checkbox-label">UI</span>
          <span class="checkbox-label">Email</span>
        </span>
      </span>
      <div class="notifications-list">
        <div *ngFor="let notification of notifications" class="notification-item {{ !notification.method.ui && !notification.method.email ? 'empty' : '' }}">
          <span class="title">{{ notification.label }}</span>
          <span class="checkboxes">
            <two-state-icon-button
              class="notification-checkbox"
              activeIcon="assets/button-icons/check-on.svg"
              inactiveIcon="assets/button-icons/check-off.svg"
              [active]="notification.method.ui"
              (onToggle)="onToggleNotification(notification, 'ui')">
            </two-state-icon-button>
            <two-state-icon-button
              class="notification-checkbox"
              activeIcon="assets/button-icons/check-on.svg"
              inactiveIcon="assets/button-icons/check-off.svg"
              [active]="notification.method.email"
              (onToggle)="onToggleNotification(notification, 'email')">
            </two-state-icon-button>
          </span>
        </div>
      </div>
    </section>
    <section class="event-history">
      <h2>
        Event History ({{ eventHistory.length }})
        <action-button class="clear-history" action="delete" #tooltip="matTooltip" matTooltip="Clear Event History" (onClick)="onClearEventHistory()"></action-button>
      </h2>
      <div class="event-history-list">
        <div
          *ngFor="let event of eventHistory"
          class="event-history-item"
          #tooltip="matTooltip"
          matTooltipClass="multiline-tooltip"
          [matTooltip]="'Event type: ' + event.type + '\n\nMessage:\n' + event.message + '\n\nDetails:\n' + event.details">
          <mat-icon class="event-icon">{{ getEventIcon(event.type) }}</mat-icon>
          <span class="title">{{ event.message }}</span>
          <span class="date">{{ event.timestamp | date: "MMM d, yy, HH:mm:ss" }}</span>
        </div>
        <div *ngIf="eventHistory.length <= 0" class="empty">
          <span class="title">No events</span>
        </div>
      </div>
    </section>
  </div>
</div>
